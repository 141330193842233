module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Gary Hynds","short_name":"Gary Hynds","start_url":"/","background_color":"#0a3b7c","theme_color":"#0a3b7c","display":"standalone","icon":"src/images/gary-hynds-favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"3733cc837f1c1a00ba3a151217e7b657"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
